<script>
import { mapState, mapActions } from 'vuex'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { VueGoodTable } from 'vue-good-table'
import { debounce } from 'vue-debounce'
import PaginationCustom from '@/components/table/Pagination.vue'

export default {
   name: 'Logs',
   components: {
      BCardCode,
      VueGoodTable,
      PaginationCustom,
   },
   data() {
      return {
         loading: true,
         serverParams: {
            columnFilters: {},
            sort: {
               field: '',
               type: '',
            },
            search: '',
            page: 1,
            perPage: 10,
         },
         pageLength: 10,
         pageOptions: ['10', '25', '50', '100'],
         searchTerm: '',
         columns: [
            {
               label: 'Nama',
               field: 'nama_user',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Resource',
               field: 'resource',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Action',
               field: 'action',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Date & Time',
               field: 'tanggal',
               sortable: false,
               thClass: 'text-center align-middle',
            },
         ],

      }
   },
   computed: {
      ...mapState({
         myData: state => state.Logs.items,
         myCount: state => state.Logs.countAll,
      }),
   },
   watch: {

   },
   mounted() {
      this.loadItems()
   },
   methods: {
      ...mapActions({
         getData: 'Logs/getData',
      }),

      // VUE GOOD TABLE
      loadItems() {
         this.loading = true
         this.getData(this.serverParams)
      },
      updateParams(newProps) {
         // eslint-disable-next-line prefer-object-spread
         this.serverParams = Object.assign({}, this.serverParams, newProps)
      },
      onPageChangePagination(page) {
         this.updateParams({ page })
         this.loadItems()
      },
      onPerPageChangePagination(perPage) {
         this.updateParams({ perPage, page: 1 })
         this.loadItems()
      },
      onColumnFilter(params) {
         this.updateParams(params)
         this.loadItems()
      },
      onSearch: debounce(function search(params) {
         this.serverParams.search = params
         this.serverParams.page = 1
         this.loadItems()
      }, 500),
   },
}
</script>

<template>
   <div>
      <b-card-code title="Logs">
         <!-- search input -->
         <b-row>
            <!-- TOOLBAR -->
            <b-col cols="12" md="6">
               <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                     v-model="searchTerm"
                     name="search"
                     class="d-inline-block mb-1"
                     placeholder="Search..."
                     autocomplete="off"
                     type="text"
                     aria-label="Search"
                     @input="onSearch"
                  />
               </div>
            </b-col>
            <b-col cols="12" md="6">
               <div class="d-flex justify-content-end">
                  <b-button-group class="mb-1">
                     <b-button variant="outline-secondary" size="sm" @click="loadItems()">
                        <feather-icon icon="RefreshCwIcon" />
                     </b-button>
                     <b-dropdown variant="outline-secondary" right text="Menu" size="sm">
                        <b-dropdown-item @click="loadItems()">
                           Refresh
                        </b-dropdown-item>
                     </b-dropdown>
                  </b-button-group>
               </div>
            </b-col>
         </b-row>

         <!-- table -->
         <vue-good-table
            ref="goodTable"
            mode="remote"
            :columns="columns"
            :rows="myData"
            :total-rows="myCount"
            :is-loading.sync="loading"
            :line-numbers="true"
            :pagination-options="{
               enabled: true,
               mode: 'pages',
            }"
            :search-options="{
               enabled: true,
               externalQuery: searchTerm,
            }"
            @on-column-filter="onColumnFilter"
            @on-search="onSearch"
         >
            <template slot="table-row" slot-scope="props">
               <!-- Column: Name -->
               <span v-if="props.column.field == 'nama_user'">
                  {{ props.row.nama_user }}
               </span>

               <span v-if="props.column.field == 'resource'">
                  {{ props.row.resource_uid }}
               </span>

               <span v-if="props.column.field == 'action'">
                  {{ props.row.action }}
               </span>

               <span v-if="props.column.field == 'tanggal'">
                  {{ (props.row.created_at) | moment("DD-MM-YYYY | HH:mm:ss") }}
               </span>

               <!-- Column: Common -->
               <!-- <span v-else>{{ props.formattedRow[props.column.field] }}</span> -->
               <!-- <span v-else>{{ props.formattedRow[props.column.field] }}</span> -->
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
               <pagination-custom
                  :total="props.total"
                  :page-length="pageLength"
                  :page-options="pageOptions"
                  :page-changed="props.pageChanged"
                  :per-page-changed="props.perPageChanged"
                  @update:page="onPageChangePagination"
                  @update:perpage="onPerPageChangePagination"
               />
            </template>
         </vue-good-table>
      </b-card-code>
   </div>
</template>
